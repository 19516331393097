var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container md-layout-column"},[_c('div',{staticClass:"view-home__part5",staticStyle:{"background-color":"rgb(252, 252, 252) !important"}},[_c('div',{staticClass:"container-fluid size mx-auto",staticStyle:{"background-color":"rgb(252, 252, 252) !important"}},[_c('div',{staticClass:"row"},[_vm._m(0),(_vm.isLoading)?[_c('div',{staticClass:"col-12 mt-4 cards-instructors instructorsLayouts"},_vm._l((4),function(index){return _c('skeleton-instructor',{key:index,staticClass:"col-md-4 col-lg-4 col-12 mb-3 mx-auto"})}),1)]:[(
              _vm.getPublicInstructorsList &&
              _vm.getPublicInstructorsList.items &&
              _vm.getPublicInstructorsList.items.length
            )?_c('div',{staticClass:"row mt-5 mx-auto"},_vm._l((_vm.getPublicInstructorsList.items),function({
                id,
                avatar,
                firstname,
                lastname,
                courses,
                classes_count
              }){return _c('div',{key:id,staticClass:"col-md-4 col-lg-3 col-sm-6 mb-2"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`}},[_c('div',{staticClass:"justify-content-center mx-auto",style:({
                    backgroundImage: `url('${
                      avatar && avatar.url
                        ? avatar.url
                        : '@/assets/images/logo/default-img-instructors.png'
                    }')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                    height: '180px',
                    width: '180px',
                    maxHeight: '180px',
                    backgroundPosition: 'center',
                  })}),_c('h4',{staticClass:"text-uppercase mt-3"},[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname)+" ")])]),_c('p',{staticClass:"font-weight-500"},[_vm._v(" "+_vm._s(classes_count)+" clases ")])],1)}),0):_c('div',[_vm._v("No hay instructores registrados")])]],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-4 mx-auto"},[_c('h2',{staticClass:"pb-3",staticStyle:{"color":"#b7227e !important"}},[_vm._v("INSTRUCTORES")]),_c('p',[_vm._v(" Los instructores de Maquillate son iconos, expertos y estrellas de rock de la industria emocionado de compartir su experiencia, sabiduría y herramientas confiables con usted. ")])])
}]

export { render, staticRenderFns }