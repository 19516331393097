<template>
  <div class="page-container md-layout-column">
    <div
      class="view-home__part5"
      style="background-color: rgb(252, 252, 252) !important"
    >
      <div
        class="container-fluid size mx-auto"
        style="background-color: rgb(252, 252, 252) !important"
      >
        <div class="row">
          <div class="col-12 mt-4 mx-auto">
            <h2 class="pb-3" style="color: #b7227e !important">INSTRUCTORES</h2>
            <p>
              Los instructores de Maquillate son iconos, expertos y estrellas de
              rock de la industria emocionado de compartir su experiencia,
              sabiduría y herramientas confiables con usted.
            </p>
          </div>
          <template v-if="isLoading">
            <div class="col-12 mt-4 cards-instructors instructorsLayouts">
              <skeleton-instructor
                v-for="index in 4"
                :key="index"
                class="col-md-4 col-lg-4 col-12 mb-3 mx-auto"
              />
            </div>
          </template>
          <template v-else>
            <div
              v-if="
                getPublicInstructorsList &&
                getPublicInstructorsList.items &&
                getPublicInstructorsList.items.length
              "
              class="row mt-5 mx-auto"
            >
              <div
                v-for="{
                  id,
                  avatar,
                  firstname,
                  lastname,
                  courses,
                  classes_count
                } in getPublicInstructorsList.items"
                :key="id"
                class="col-md-4 col-lg-3 col-sm-6 mb-2"
              >
                <router-link
                  :to="`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`"
                  style="text-decoration: none"
                >
                  <div
                    :style="{
                      backgroundImage: `url('${
                        avatar && avatar.url
                          ? avatar.url
                          : '@/assets/images/logo/default-img-instructors.png'
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      borderRadius: '50%',
                      height: '180px',
                      width: '180px',
                      maxHeight: '180px',
                      backgroundPosition: 'center',
                    }"
                    class="justify-content-center mx-auto"
                  ></div>
                  <h4 class="text-uppercase mt-3">
                    {{ firstname }} {{ lastname }}
                  </h4>
                </router-link>
                <p class="font-weight-500">
                  {{ classes_count }} clases
                </p>
              </div>
            </div>

            <div v-else>No hay instructores registrados</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_PUBLIC_INSTRUCTORS_LIST } from '../../store/actions/public';
import SkeletonInstructor from '../Components/skeletons/SkeletonInstructor';

export default {
  name: 'PublicInstructors',
  components: {
    SkeletonInstructor,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  metaInfo() {
    return {
      title:  'Instructores - Maquillate.com'
    }
  },
  data() {
    return {
      isLoading: true,
      perPage: 15,
    };
  },
  computed: {
    ...mapGetters(['getPublicInstructorsList']),
  },
  mounted() {
    this.getInstructors();
  },

  methods: {
    getInstructors(
      filter = {
        order_by: 'ASC_firstname',
        per_page: this.perPage,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_INSTRUCTORS_LIST, filter)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los instructores',
          );
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    getCourseInstructorCourseVideo(courses = []) {
      if (!courses.length) return 0;

      return courses.reduce((acc, { course }) => {
        return acc + course.course_videos.length;
      }, 0);
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.navbar-mobile {
  &.navbar-Header {
    display: none;
    margin-top: -2px;
    height: 68px;
    width: 100vw;
    background: transparent;
    position: fixed;
    transform: translate3d(0, 0, 0);
    transition: 0.3s all ease-out;
    z-index: 7;

    a {
      font-family: inherit !important;
    }

    @media (max-width: 1000px) {
      display: block !important;
    }

    .view-home-maquillate__header {
      margin-top: 13px;
    }
  }
}

.navbar-Header {
  margin-top: -2px;
  height: 68px;
  width: 100vw;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  z-index: 10;

  a {
    font-family: inherit !important;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  .view-home-maquillate__header {
    margin-top: 13px;
  }
}

.bg-transparent-header {
  background: transparent;
}

.bg-black {
  background: #000;
}

.navbar-Header.hidden-navbar-Header {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.textCard {
  height: 14vh;
  overflow-y: auto;
}

.instructorCard {
  margin-top: 1rem;
}

.view-home__part5 .cards-instructors {
  text-align: center !important;
}

.instructorsLayouts {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px !important;
}
</style>
